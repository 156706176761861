<template>
  <v-card class="profile-password-card" :class="{ 'full-height': fullHeight }" elevation="2">
    <v-toolbar flat>
      <v-toolbar-title>{{ $t("Users.ChangePassword") }}</v-toolbar-title>
    </v-toolbar>

    <v-divider></v-divider>

    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field
            :value="password.oldPassword"
            @input="$emit('change:password', { value: $event, field: 'oldPassword' })"
            :type="showOldPassword ? 'text' : 'password'"
            :label="$t('Users.OldPassword')"
            :error-messages="oldPasswordErrors"
          />

          <v-text-field
            :value="password.password"
            @input="$emit('change:password', { value: $event, field: 'password' })"
            :type="showPassword ? 'text' : 'password'"
            :label="$t('Users.NewPassword')"
            :error-messages="passwordErrors"
          />

          <v-text-field
            :value="password.repeatPassword"
            @input="$emit('change:password', { value: $event, field: 'repeatPassword' })"
            :type="showRepeatPassword ? 'text' : 'password'"
            :label="$t('Users.RepeatNewPassword')"
            :error-messages="repeatPasswordErrors"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
const passwordKeys = ["oldPassword", "password", "repeatPassword"];

export default {
  name: "VProfilePasswordCard",

  props: {
    password: {
      type: Object,
      required: true,
      validator: (password) => passwordKeys.every((key) => key in password),
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
  },

  inject: ["$v"],

  data() {
    return {
      showOldPassword: false,
      showPassword: false,
      showRepeatPassword: false,
    };
  },

  computed: {
    oldPasswordErrors() {
      const errors = [];
      if (!this.$v.password.oldPassword.$dirty) return errors;
      !this.$v.password.oldPassword.minLength &&
        errors.push(
          `Old password must have at least ${this.$v.password.oldPassword.$params.minLength.min} letters.`,
        );
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.password.$dirty) return errors;
      !this.$v.password.password.minLength &&
        errors.push(
          `Password must have at least ${this.$v.password.password.$params.minLength.min} letters.`,
        );
      return errors;
    },
    repeatPasswordErrors() {
      const errors = [];
      if (!this.$v.password.repeatPassword.$dirty) return errors;
      !this.$v.password.repeatPassword.sameAsPassword &&
        errors.push(this.$t("Validation.PasswordConfirmed"));
      return errors;
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-password-card {
  .full-height {
    height: 100%;
  }
}
</style>