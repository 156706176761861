<template>
  <div class="profile-management">
    <div class="profile-management-header">
      <v-btn class="mr-3" @click="$router.go(-1)"> {{ $t("GlobalUI.Cancel") }} </v-btn>
      <v-btn color="primary" @click="save" :loading="isBusy"> {{ $t("GlobalUI.Save") }} </v-btn>
    </div>
    <v-alert v-if="errorCode" text outlined color="deep-orange" icon="mdi-fire" dismissible>
      {{ $t(`ServerResponse.${errorCode}`) }}
    </v-alert>
    <div class="profile-management-grid">
      <v-profile-general-card
        :user="{ ...user, cacheEmail }"
        @change:user="handleChangeUser"
        full-height
      />
      <v-profile-password-card
        :password="password"
        @change:password="handleChangePassword"
        full-height
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, sameAs, minLength } from "vuelidate/lib/validators";

import VProfileGeneralCard from "./cards/VProfileGeneralCard";
import VProfilePasswordCard from "./cards/VProfilePasswordCard";

import profileService from "../../services/profile.service";
import { username, email, PASSWORD_MAX_LENGTH } from "../../services/validators.service";

import { serverErrorCodes } from "../../constants/response";

const { INTERNAL_SERVER_ERROR } = serverErrorCodes;

export default {
  name: "VProfileManagement",

  components: { VProfileGeneralCard, VProfilePasswordCard },

  mixins: [validationMixin],

  metaInfo() {
    return { title: "Profile" };
  },

  validations() {
    return {
      user: {
        username: { required, username },
        email: { required, email },
        firstName: { required },
        lastName: { required },
      },

      password: {
        oldPassword: { minLength: minLength(PASSWORD_MAX_LENGTH) },
        password: { minLength: minLength(PASSWORD_MAX_LENGTH) },
        repeatPassword: { sameAsPassword: sameAs("password") },
      },
    };
  },

  provide() {
    return {
      $v: this.$v,
    };
  },

  data() {
    return {
      isBusy: false,

      user: null,
      cacheEmail: null,
      password: {
        oldPassword: "",
        password: "",
        repeatPassword: "",
      },

      isVerifyBusy: false,
      errorCode: null,
    };
  },

  created() {
    const user = this.$store.getters["auth/user"];

    this.cacheEmail = user.email;

    this.user = {
      avatar: user.avatar ? { ...user.avatar } : null,
      username: user.username,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
    };
  },

  methods: {
    ...mapActions({
      updateUser: "auth/updateUser",
    }),
    handleChangeUser({ value, field }) {
      this.user = { ...this.user, [field]: value };
    },
    handleChangePassword({ value, field }) {
      this.password[field] = value;
    },
    async save() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.isBusy = true;

      const { oldPassword, password } = this.password;

      this.user.email = this.user.email.toLowerCase();
      const { avatar, username, email, firstName, lastName } = this.user;
      const user = {
        avatar,
        username,
        email,
        firstName,
        lastName,
        oldPassword,
        password,
      };
      if (!oldPassword) delete user.oldPassword;
      if (!password) delete user.password;

      if (this.cacheEmail !== user.email) {
        user.emailVerifiedAt = null;
        this.cacheEmail = user.email;
      }

      try {
        await profileService.update(user);

        if (user.emailVerifiedAt) delete user.emailVerifiedAt;
        this.updateUser(user);

        this.password.oldPassword = "";
        this.password.password = "";
        this.password.repeatPassword = "";
      } catch ({ response }) {
        if (!response || !response.data) this.errorCode = INTERNAL_SERVER_ERROR;
        else this.errorCode = response.data.code || INTERNAL_SERVER_ERROR;
      } finally {
        this.isBusy = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-management-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.profile-management-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
}

@media screen and (max-width: 991px) {
  .profile-management-grid {
    grid-template-columns: 100%;
  }
}
</style>