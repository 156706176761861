<template>
  <div class="profile-management">
    <v-profile-management />
  </div>
</template>

<script>
import VProfileManagement from "../../components/profile/VProfileManagement";

export default {
  name: "ProfileManagement",

  middleware: ["auth", "check-first-config"],

  components: { VProfileManagement },
};
</script>

<style lang="scss" scoped>
</style>