<template>
  <v-card class="profile-general-card" :class="{ 'full-height': fullHeight }" elevation="2">
    <v-toolbar flat>
      <v-toolbar-title>{{ $t("GlobalUI.General") }}</v-toolbar-title>
    </v-toolbar>

    <v-divider></v-divider>

    <v-card-text class="profile-general-card__body">
      <div class="profile-general-avatar">
        <v-photo-uploader
          class="avatar-uploader"
          :photo="user.avatar"
          size="sm"
          type="AVATAR"
          @upload="(value) => $emit('change:user', { value, field: 'avatar' })"
          @clear="() => $emit('change:user', { value: null, field: 'avatar' })"
        />
      </div>
      <div class="profile-general-controls">
        <v-text-field
          :value="user.username"
          @input="$emit('change:user', { value: $event, field: 'username' })"
          :label="$t('Users.UserName')"
          :error-messages="usernameErrors"
          @blur="$v.user.username.$touch()"
        />
        <div class="profile-email-block">
          <v-text-field
            :value="user.email"
            @input="$emit('change:user', { value: $event, field: 'email' })"
            :label="$t('Auth.Email')"
            :error-messages="emailErrors"
            @blur="$v.user.email.$touch()"
            :hide-details="!isEmailVerified ? 'auto' : false"
          >
            <template v-if="!isEmailVerified" v-slot:append>
              <v-icon color="warning"> mdi-alert </v-icon>
            </template>
          </v-text-field>
          <div v-if="!isEmailVerified" class="profile-email-not-verify">
            <span class="orange--text">E-Mail not verify</span>
            <a
              href="javascript:void(0)"
              @click="handleClickResend"
              v-text="`${$t('Auth.ResendVerLink')}?`"
            />
            <v-progress-circular
              class="ml-2"
              v-if="isVerifyBusy"
              indeterminate
              color="default"
              size="16"
              width="2"
            />
          </div>
        </div>
        <v-text-field
          :value="user.firstName"
          @input="$emit('change:user', { value: $event, field: 'firstName' })"
          :label="$t('Users.FirstName')"
          :error-messages="firstNameErrors"
          @blur="$v.user.firstName.$touch()"
        />
        <v-text-field
          :value="user.lastName"
          @input="$emit('change:user', { value: $event, field: 'lastName' })"
          :label="$t('Users.LastName')"
          :error-messages="lastNameErrors"
          @blur="$v.user.lastName.$touch()"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { v4 as uuid } from "uuid";
import VPhotoUploader from "../../photos/VPhotoUploader";

import resend from "../../../mixins/resend";

const userKeys = ["avatar", "username", "firstName", "lastName", "email", "cacheEmail"];

export default {
  name: "VUserGeneralCard",

  components: { VPhotoUploader },

  mixins: [resend],

  inject: ["$v"],

  props: {
    user: {
      type: Object,
      required: true,
      validator: (user) => userKeys.every((key) => key in user),
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    usernameErrors() {
      const errors = [];
      if (!this.$v.user.username.$dirty) return errors;
      !this.$v.user.username.username && errors.push("Must be valid user name");
      !this.$v.user.username.required && errors.push("User name is required");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.user.email.$dirty) return errors;
      !this.$v.user.email.email && errors.push("Must be valid e-mail address");
      !this.$v.user.email.required && errors.push("E-mail is required");
      return errors;
    },
    firstNameErrors() {
      const errors = [];
      if (!this.$v.user.firstName.$dirty) return errors;
      !this.$v.user.firstName.required && errors.push("First name is required");
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.user.lastName.$dirty) return errors;
      !this.$v.user.lastName.required && errors.push("Last name is required");
      return errors;
    },

    isEmailVerified() {
      const user = this.$store.getters["auth/user"];
      return Boolean(user.emailVerifiedAt);
    },
  },

  methods: {
    async handleClickResend() {
      await this.resend(this.user.email);
      if (this.errorCode) return;

      this.$store.set("notifications/notify", {
        id: uuid(),
        manager: "notify",
        text: this.$t("Auth.VerificationLinkSentExtend", { email: this.email }),
        type: "success",
        timeout: -1,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-general-card {
  .full-height {
    height: 100%;
  }

  &__body {
    display: grid;
    grid-template-columns: 240px auto;
    gap: 25px;
  }
}

.profile-general-avatar {
  .avatar-uploader {
    max-width: 300px;
    margin: 0 auto;
  }
}

.profile-email-not-verify {
  margin-top: 5px;
  margin-bottom: 10px;
  font-weight: bold;
  span {
    margin-right: 5px;
  }
}

@media screen and (max-width: 768px) {
  .profile-general-card {
    &__body {
      grid-template-columns: 200px auto;
    }
  }
}

@media screen and (max-width: 576px) {
  .profile-general-card {
    &__body {
      grid-template-columns: 100%;
    }
  }
}
</style>